/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-self-assign */
/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
import Result from 'iotera-base/result';
import { BarLoader } from 'react-spinners';
import React, { Component } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import MetaTags from 'react-meta-tags';
import 'flatpickr/dist/themes/airbnb.css';
//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';
import Transaction from '../../../Component/transactionsTable';
import { isEmpty, size } from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import ReactTooltip from 'react-tooltip';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Table,
  InputGroup,
  PaginationItem,
  PaginationLink,
  Pagination,
  Progress,
  ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
//Import Breadcrumb
// Iotera
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { list_tags } from '../../store/tag/actions';

import { Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import {
  list_business_points,
  list,
  read,
  updateRefund,
  refunded,
  nextList,
  get_snapshot_monthly,
  download_data,
  search_data,
} from '../../store/transaction/actions';
import ButtonDownload, {
  APPLICATION_ID,
  buildTitle,
  PROJECT_HANDLE,
  REFUND_PERIOD,
  getTableTransaction,
  getFormatReport,
  getSheetData,
  PAGE_SIZE,
} from 'Apps';

const paymentOptions = [
  { label: 'All transactions', value: 'all' },
  { label: 'Settlement', value: 'settlement' },
  { label: 'Refund Pending', value: 'refund_pending' },
  { label: 'Timeout', value: 'timeout' },
  { label: 'Cancel', value: 'cancel' },
  { label: 'Failure', value: 'failure' },
  { label: 'Expire', value: 'expire' },
  { label: 'Refunded', value: 'refunded' },
  { label: 'Outstanding', value: 'outstanding' },
  { label: 'Credit', value: 'credit' },
  { label: 'Debt', value: 'debt' },
];

const dispenseOptions = [
  { label: 'All dispenses', value: 'all' },
  { label: 'No Dispense', value: 'no_dispense' },
  { label: 'Success', value: 'success' },
  { label: 'Forced Dispense', value: 'forced_dispense' },
  { label: 'No Dispense VM TimeOut', value: 'no_dispense_vm_timeout' },
  {
    label: 'No Dispense Column Unresponsive',
    value: 'no_dispense_column_unresponsive',
  },
  { label: 'No Dispense VM error', value: 'no_dispense_vm_error' },
  { label: '-', value: '-' },
];

const bankOptions = [
  { label: 'All', value: 'all' },
  { label: 'CASH', value: 'CASH' },
  { label: 'ECR', value: 'ECR' },
  { label: 'RFID', value: 'RFID' },
  { label: 'QRIS-MIDTRANS', value: 'QRIS-MIDTRANS' },
  { label: 'QRIS-MIDTRANS-PARTNER', value: 'QRIS-MIDTRANS-PARTNER' },
  { label: 'QRIS-BANKNOBU', value: 'QRIS-BANKNOBU' },
];

let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj;
}
import Pages403 from '../pages-403';
import { NAVBAR, PERMISION } from 'Apps';
import { loading } from 'Base/store/actions';
const navbar = NAVBAR;
const permision = PERMISION;
class TransactionList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.refDatePicker = React.createRef();
    const cleanValue = value => typeof value === 'string' ? value.replace('#', '') : value;

    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = cleanValue(params.get('device_id'));
    let trx = cleanValue(params.get('trx_status'));
    let dsp = cleanValue(params.get('dsp_status'));
    let bank = cleanValue(params.get('bank_status'));
    let start = cleanValue(params.get('dateStart'));
    let end = cleanValue(params.get('dateEnd'));
    let tag = cleanValue(params.get('tags'));
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));

    let businessPoints = { label: 'All points', value: 'all' };
    let transactionStatus = { value: 'all' };
    let dispenseStatus = { value: 'all' };
    let payment = { value: 'all' };
    let tags = { label: 'No Tags', value: null };
    if (name != undefined || name != null) {
      businessPoints = { value: name };
    }
    if (trx != undefined || trx != null) {
      transactionStatus = { value: trx };
    }
    if (dsp != undefined || dsp != null) {
      dispenseStatus = { value: dsp };
    }
    if (bank != undefined || bank != null) {
      payment = { value: bank };
    }
    if (tags != undefined || tags != null) {
      tags = { value: tag };
    }
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;

    const date2 = new Date();
    const year = date2.getFullYear();
    const month = date2.getMonth();
    const day = date2.getDate();
    let newDate = new Date(year, month, day - REFUND_PERIOD);
    let startDate = newDate;
    let endDate = new Date();
    let arrDate = [newDate, endDate];
    if (start != undefined || start != null) {
      arrDate = [dateStart, dateEnd];
      startDate = dateStart;
    } else {
      arrDate = [newDate, endDate];
    }
    const trxColumn = getTableTransaction(
      this.handleRefundedMidTrans,
      this.handleDetail
    );

    this.state = {
      startDate,
      endDate,
      arr_timeStamp: [],
      body: [],
      progress: 0,
      loading: false,
      isDownload: false,
      report_list: [],
      currentPage: 1,
      totalPages: 10,
      trxColumn,
      list: [],
      loadingData: false,
      modalLoading: false,
      modal: false,
      modalDetail: false,
      products: [],
      trx: [],
      confirm_msg: false,
      reportName: 'report',
      // lenghtAllData:0,
      confirm_alert: false,
      bpfilterlist: [],
      dateRange: arrDate,
      role: '',
      anchor: false,
      businessPoints: businessPoints,
      payment: payment,
      tags: tags,
      transactionStatus: transactionStatus,
      dispenseStatus: dispenseStatus,
      searchTerm: '',
      modalDownload: false,
      modalLoadingDownload: false,
      modalFilter: false,
    };

    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleTransactionStatus = this.handleTransactionStatus.bind(this);
    this.handlePaymentMethod = this.handlePaymentMethod.bind(this);
    this.handleTags = this.handleTags.bind(this);
    this.handleDispenseStatus = this.handleDispenseStatus.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.handleRefunded = this.handleRefunded.bind(this);
    this.handleRefundedMidTrans = this.handleRefundedMidTrans.bind(this);
    this.handleRefundDate = this.handleRefundDate.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleBackFirst = this.handleBackFirst.bind(this);

    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleDetail = this.handleDetail.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.showToast = this.showToast.bind(this);
    this.showToastFailed = this.showToastFailed.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
    this.toggleLoadingDownload = this.toggleLoadingDownload.bind(this);
    this.toggleDownload = this.toggleDownload.bind(this);
    this.dataChange = this.dataChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  handleFilter = () => {
    const {
      businessPoints,
      dateRange,
      transactionStatus,
      dispenseStatus,
      payment,
      tags,
      searchTerm,
    } = this.state;
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: dateRange,
      transactionStatus: transactionStatus.value,
      dispenseStatus: dispenseStatus.value,
      bank: payment.value,
      tags: tags.value,
    };
    this.setState({ currentPage: 1 });
    var searchParams = new URLSearchParams(window.location.search);

    if (businessPoints.value == 'all') {
      searchParams.delete('device_id');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('device_id', businessPoints.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }

    if (transactionStatus.value == 'all') {
      searchParams.delete('trx_status');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('trx_status', transactionStatus.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }

    if (dispenseStatus.value == 'all') {
      searchParams.delete('dsp_status');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('dsp_status', dispenseStatus.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }

    if (dateRange[0] == null || dateRange[0] == undefined) {
      searchParams.delete('dateStart');
      searchParams.delete('dateEnd');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('dateStart', dateRange[0].getTime());
      searchParams.set('dateEnd', dateRange[1].getTime());
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }

    if (payment.value == 'all') {
      searchParams.delete('bank_status');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('bank_status', payment.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }

    if (tags.value == '' || tags.value == null) {
      searchParams.delete('tags');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('tags', tags.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }

    if (searchTerm == '') {
      this.props.LoadTransactionList(payload);
    } else {
      this.props.SearchData(searchTerm);
    }

    this.setState({ loading: true });
  };

  toggleFilter() {
    // this.flatpickrInstance.set('maxDate', 'today');
    this.setState(prevState => ({
      modalFilter: !prevState.modalFilter,
    }));
  }

  handleSearchChange = event => {
    this.setState({ searchTerm: event.target.value });
    event.preventDefault();
    const payload = {
      text: event.target.value,
    };
    if (event.target.value == '') {
      this.componentDidMount();
    } else {
      this.props.SearchData(payload);
    }
  };

  dataChange() {
    setTimeout(() => {
      let dataTable = this.node.current.table.props.data;
      const lenghtTable = dataTable.length;
      this.setState({ lenghtTable });
    }, 1000);
  }

  timeConvert(n) {
    var num = n;
    var day = num / 60 / 24;
    var rday = Math.floor(day);
    var hours = (day - rday) * 24;
    var rhours = Math.round(hours);
    return rday + ' Days(s) and ' + rhours + ' hours(s) ';
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleDetail() {
    this.setState(prevState => ({
      modalDetail: !prevState.modalDetail,
    }));
  }
  toggleDownload(bool) {
    this.setState(prevState => ({
      modalDownload: bool != undefined ? bool : !prevState.modalDownload,
    }));
  }
  toggleLoadingDownload(bool) {
    this.setState(prevState => ({
      modalLoadingDownload:
        bool != undefined ? bool : !prevState.modalLoadingDownload,
    }));
  }
  // untuk ke Payment Server
  handleRefundedMidTrans = arg => {
    const product = arg;
    this.setState({
      products: {
        id: product.id,
        amount: product.payment.amount,
        order_id: product.detail.order_id,
        device_id: product.product.device_id,
        name: product.name,
        method: product.payment.method,
      },
    });
    this.toggle();
  };
  handleDetail = arg => {
    const trx = arg;
    // this.toggleDetail();
    this.props.history.push(
      `/transaction/detail/${safeDeepGet(
        trx,
        ['detail', 'order_id'],
        'default'
      )}`
    );
  };
  handleClick() {
    this.setState({
      drp_secondary_sm: !this.state.drp_secondary_sm,
    });
  }
  handleClose = () => {
    this.setState(prevState => ({
      anchor: !prevState.anchor,
    }));
  };
  handleRefundDate = date => {
    if (date) {
      const d = new Date(0, 0, 0, 0, 0, 0, date).toLocaleDateString('en-GB');
      return d;
    }
  };
  showToastFailed(text) {
    const { isEdit } = this.state;
    var toastType = 'error';
    var message = 'Gagal Download Data: ' + text;
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '10000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'info';
    var message = 'Refund sedang di Proses';
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '10000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  // untuk ke Payment Server
  handleValidRefundSubmit = (e, values) => {
    // this.dataChange();
    const { onRefund } = this.props;
    const { products } = this.state;
    const refunded = {
      ...values,
      id: products.id,
      order_id: products.order_id,
      amount: products.amount,
      device_id: products.device_id,
      name: products.name,
    };
    if (
      products.method == 'QRIS-MIDTRANS' ||
      products.method == 'QRIS-BANKNOBU' ||
      products.method == 'QRIS-MIDTRANS-PARTNER'
    ) {
      onRefund(refunded);
    } else {
      this.handleRefunded(products);
    }
    this.toggle();
    this.showToast();

    setTimeout(() => {
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
        tags: this.state.tags.value,
      };
      this.setState({ currentPage: 1 });
      this.props.LoadTransactionList(payload);
      this.setState({ loading: true });
    }, 5000);
  };
  handleValidSubmitMonthly = (e, values) => {
    // this.dataChange();
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      tags: this.state.tags.value,
      name: this.state.reportName,
      cost: {
        internet: Number(values.internet ?? 0),
        electricity: Number(values.listrik ?? 0),
        promo: Number(values.promo ?? 0),
        maintenance: Number(values.maintenance ?? 0),
        landlord: Number(values.landlord ?? 0),
        other: Number(values.dll ?? 0),
      },
    };

    // console.log(payload);
    this.toggleLoadingDownload();
    this.props.DownloadData(payload);
    // this.setState({ loading: true, modalLoadingDownload:true });
  };

  //Untuk Ke  Payemnt Server
  handleRefund = row => {
    // this.dataChange();
    const payload = {
      order_id: safeDeepGet(row, 'id', ''),
      transaction_status: 'refund_pending',
    };
    this.props.RefundTransaction(payload);
    const payload1 = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      tags: this.state.tags.value,
    };
    this.props.LoadTransactionList(payload1);
    this.setState({ currentPage: 1 });
    this.setState({ loading: true });
  };

  // Untuk Ke Firebase
  handleRefunded = row => {
    // this.dataChange();
    if (confirm('Are you sure you want to complete the refund process?')) {
      var time = Date.now();
      const payload = {
        order_id: safeDeepGet(row, 'id', ' '),
        transaction_status: 'refunded',
        refund_time: time,
      };
      this.props.RefundTransaction(payload);
    } else {
      const payload1 = {
        businessPoints: this.state.businessPoints.value,
        dateRange: this.state.dateRange,
        transactionStatus: this.state.transactionStatus.value,
        dispenseStatus: this.state.dispenseStatus.value,
        bank: this.state.payment.value,
        tags: this.state.tags.value,
      };
      this.props.LoadTransactionList(payload1);
    }
    const payload1 = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      tags: this.state.tags.value,
    };
    this.props.LoadTransactionList(payload1);
    this.setState({ loading: true });
  };

  handleBusinessPoints = businessPoints => {
    this.setState({ currentPage: 1 });

    // this.dataChange();
    this.setState({ businessPoints, loadingData: true, modalLoading: true });

    const payload = {
      businessPoints: businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      tags: this.state.tags.value,
    };
    this.props.LoadTransactionList(payload);
    var searchParams = new URLSearchParams(window.location.search);
    if (businessPoints.value == 'all') {
      searchParams.delete('device_id');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('device_id', businessPoints.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }
    this.setState({ loading: true });
  };

  handleTags = tags => {
    this.setState({ currentPage: 1 });

    // this.dataChange();
    this.setState({ tags, loadingData: true, modalLoading: true });

    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      tags: tags.value,
    };
    this.props.LoadTransactionList(payload);
    var searchParams = new URLSearchParams(window.location.search);
    if (tags.value == '') {
      searchParams.delete('tags');
      const search = searchParams.toString();
      this.props.history.push({
        pathname: '/transaction/list',
        search: search ? `?${search}` : '', // Avoids appending #
      });
    } else {
      searchParams.set('tags', tags.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: `?${searchParams.toString()}`,
      });
    }

    this.setState({ loading: true });
  };
  handlePaymentMethod = payment => {
    this.setState({ currentPage: 1 });

    // this.dataChange();
    this.setState({ payment, loadingData: true, modalLoading: true });

    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: payment.value,
      tags: this.state.tags.value,
    };
    this.props.LoadTransactionList(payload);
    var searchParams = new URLSearchParams(window.location.search);
    if (payment.value == 'all') {
      searchParams.delete('bank_status');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('bank_status', payment.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }
    this.setState({ loading: true });
  };

  handleTransactionStatus = transactionStatus => {
    // this.dataChange();
    this.setState({ currentPage: 1 });

    this.setState({ transactionStatus, loadingData: true, modalLoading: true });

    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      tags: this.state.tags.value,
    };
    this.props.LoadTransactionList(payload);

    var searchParams = new URLSearchParams(window.location.search);
    if (transactionStatus.value == 'all') {
      searchParams.delete('trx_status');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('trx_status', transactionStatus.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }
    this.setState({ loading: true });
  };

  handleDispenseStatus = dispenseStatus => {
    this.setState({ currentPage: 1 });

    this.setState({ dispenseStatus, loadingData: true, modalLoading: true });
    // this.dataChange();

    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: dispenseStatus.value,
      bank: this.state.payment.value,
      tags: this.state.tags.value,
    };
    this.props.LoadTransactionList(payload);

    var searchParams = new URLSearchParams(window.location.search);
    if (dispenseStatus.value == 'all') {
      searchParams.delete('dsp_status');
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('dsp_status', dispenseStatus.value);
      this.props.history.push({
        pathname: '/transaction/list',
        search: searchParams.toString(),
      });
    }
    this.setState({ loading: true });
  };

  handleStartDateChange = startDate => {
    if (!startDate) return;

    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + 30); // Maksimum 30 hari dari startDate

    this.setState({ startDate, endDate: null }); // Reset End Date agar pengguna memilih ulang

    // Atur batasan untuk tanggal akhir
    this.flatpickrEndInstance.set('minDate', startDate);
    this.flatpickrEndInstance.set('maxDate', maxDate);
  };
  handleEndDateChange = endDate => {
    const { startDate } = this.state;
    if (!startDate || !endDate) return; // Pastikan Start Date sudah dipilih

    this.setState({ endDate }, () => {
      this.handleDateRange(); // Auto Submit setelah pilih End Date
    });
  };
  handleDateRange = () => {
    const { startDate, endDate } = this.state;
    if (!startDate || !endDate) return;

    this.setState({
      loadingData: true,
      modalLoading: true,
      loading: true,
      currentPage: 1,
    });

    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: [startDate, endDate],
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      tags: this.state.tags.value,
    };
    this.props.LoadTransactionList(payload);

    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set('dateStart', startDate.getTime());
    searchParams.set('dateEnd', endDate.getTime());

    this.props.history.push({
      pathname: '/transaction/list',
      search: searchParams.toString(),
    });
  };

  // handleDateRange = (dateRange, dateStr, instance) => {
  //   // this.dataChange();
  //   if (dateRange.length < 2) {
  //     console.log('Ignoring.. only start date');
  //     const startDate = dateRange[0];

  //     // Calculate maxDate as 30 days from the start date
  //     const maxDate = new Date(startDate);
  //     maxDate.setDate(startDate.getDate() + 30);

  //     const minDate = new Date(startDate);
  //     minDate.setDate(startDate.getDate() - 30);

  //     // Set the maxDate option in Flatpickr
  //     this.flatpickrInstance.set('maxDate', maxDate);
  //     this.flatpickrInstance.set('minDate', minDate);
  //   } else {
  //     console.log(dateRange);
  //     this.setState({ dateRange, loadingData: true, modalLoading: true });
  //     this.setState({ loading: true });
  //     this.setState({ currentPage: 1 });
  //     this.flatpickrInstance.set('maxDate', 'today');
  //     this.flatpickrInstance.set('minDate', null);
  //     const payload = {
  //       businessPoints: this.state.businessPoints.value,
  //       dateRange: dateRange,
  //       transactionStatus: this.state.transactionStatus.value,
  //       dispenseStatus: this.state.dispenseStatus.value,
  //       bank: this.state.payment.value,
  //       tags: this.state.tags.value,
  //     };
  //     this.props.LoadTransactionList(payload);
  //     var searchParams = new URLSearchParams(window.location.search);
  //     if (dateRange[0] == null || dateRange[0] == undefined) {
  //       searchParams.delete('dateStart');
  //       searchParams.delete('dateEnd');
  //       this.props.history.push({
  //         pathname: '/transaction/list',
  //         search: searchParams.toString(),
  //       });
  //     } else {
  //       searchParams.set('dateStart', dateRange[0].getTime());
  //       searchParams.set('dateEnd', dateRange[1].getTime());
  //       this.props.history.push({
  //         pathname: '/transaction/list',
  //         search: searchParams.toString(),
  //       });
  //     }
  //   }
  // };

  handleSwitch = e => {
    // this.dataChange();
    const value = e.value;
    this.setState({ refundStatus: e });
    if (value == 'refund') {
      if (this.state.SvFranketransactionColumns.length < 6) {
        const date = new Date();
        const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
        var one_day = 1000 * 60;
        this.state.SvFranketransactionColumns.push({
          dataField: 'refund',
          isDummyField: true,
          text: 'Refund',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
              'settlement' ? (
                row.time.timestamp >= twoDay ? (
                  <Tippy
                    content={this.timeConvert(
                      Math.ceil((row.time.timestamp - twoDay) / one_day)
                    )}
                  >
                    <button
                      type="button"
                      color="success"
                      className="btn btn-sm btn-rounded btn-success"
                      onClick={() => this.handleRefundedMidTrans(row)}
                    >
                      Refund
                    </button>
                  </Tippy>
                ) : (
                  ' '
                )
              ) : safeDeepGet(row, ['detail', 'transaction_status'], '-') ==
                'refunded' ? (
                <>
                  <p className="mb-0">
                    {this.handleValidRefundDate(row.detail.refund_time)}
                  </p>
                  <p className="mb-1">
                    {this.handleValidTime(row.detail.refund_time)}
                  </p>
                </>
              ) : (
                ' '
              )}
            </>
          ),
        });
      }
      // this.setState({
      //   toggleSwitch: true,
      // });
      // this.state.FranketransactionColumns.pop();
    } else {
      if (this.state.SvFranketransactionColumns.length > 5) {
        this.state.SvFranketransactionColumns.pop();
      }
    }

    //  setTimeout(window.location.reload(false), 5000)
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      tags: this.state.tags.value,
    };
    this.props.LoadTransactionList(payload);
  };

  componentDidMount() {
    this.setState({ loadingData: true, modalLoading: true });
    if (safeDeepGet(navbar, ['Transaction', 'edit'], 'off') == 'off') {
      this.state.trxColumn.pop();
    }
    this.props.ListTags();
    const tag = localStorage.getItem('tag') ?? JSON.parse(null);
    let dataPayload = {};
    if (tag != null && tag !== 'null') {
      this.setState({
        tags: { value: tag },
      });
      safeDeepSet(dataPayload, 'tags', tag);
    }

    this.props.LoadBpFilterList(dataPayload);

    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      tags: this.state.tags.value,
    };
    this.setState({ currentPage: 1 });
    // this.flatpickrInstance.set('maxDate', 'today');
    this.props.LoadTransactionList(payload);
    // setTimeout(
    //   function () {
    //     //Start the timer
    //     this.dataChange();
    //   }.bind(this),
    //   1500
    // );
    // setInterval(async () => {
    //   this.props.LoadTransactionList(payload);
    // }, 3000);
    // Load transactions
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    if (list.length < 25) {
      this.setState({ lengthData: list.length });
    } else {
      this.setState({ lengthData: 25 });
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { orders } = this.props;
    if (!isEmpty(orders) && size(prevProps.orders) !== size(orders)) {
      this.setState({ orders: {}, isEdit: false });
    }

    if (this.props.result !== prevProps.result) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['result', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        const body = safeDeepGet(this.props, ['result', 'body'], []);
        const lastDocs = safeDeepGet(this.props, ['result', 'lastDocs'], null);
        const fistDocs = safeDeepGet(this.props, ['result', 'fistDocs'], null);
        this.toggleLoadingDownload(false);
        this.toggleFilter();
        this.setState({
          fistDocs: fistDocs,
          lastDocs: lastDocs,
          loadingData: false,
          modalLoading: false,

          loading: false,
          body,
        });
      }
    }
    if (this.props.progressResult !== prevProps.progressResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['progressResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const progress = safeDeepGet(
          this.props,
          ['progressResult', 'progress'],
          100
        );
        this.setState({
          progress,
        });
      }
    }
    if (this.props.resultNext !== prevProps.resultNext) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['resultNext', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const lastDocs = safeDeepGet(
          this.props,
          ['resultNext', 'lastDocs'],
          null
        );
        const fistDocs = safeDeepGet(
          this.props,
          ['resultNext', 'fistDocs'],
          null
        );
        const lastDocsPrev = safeDeepGet(
          this.props,
          ['resultNext', 'lastDocsPrev'],
          null
        );
        const body = safeDeepGet(this.props, ['resultNext', 'body'], []);
        this.setState({
          fistDocs: fistDocs,
          lastDocsPrev: lastDocsPrev,
          lastDocs: lastDocs,
          loadingData: false,
          modalLoading: false,
          body,
          loading: false,
        });
      }
    }
    if (this.props.resultSearch !== prevProps.resultSearch) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['resultSearch', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const body = safeDeepGet(this.props, ['resultSearch', 'body'], []);
        this.setState({
          loading: false,
          loadingData: false,
          body,
        });
      }
    }

    if (this.props.resultDownload !== prevProps.resultDownload) {
      const code = safeDeepGet(
        this.props,
        ['resultDownload', 'code'],
        Result.UNKNOWN_ERROR
      );
      const text = safeDeepGet(
        this.props,
        ['resultDownload', 'text'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.FAILED || code == 9) {
        this.showToastFailed(text);
        return;
      } else if (code == 0) {
        this.setState({
          progress: 100,
        });
        setTimeout(
          function () {
            //Start the timer
            this.toggleLoadingDownload(false);
            this.toggleDownload(false);
            // this.setState({ loading: true });

            this.setState({
              loading: false,
              modalLoadingDownload: false,
              isDownload: false,
              progress: 0,
            });
          }.bind(this),
          2000
        );
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { orders } = this.props;
    this.setState({
      orders: orders.filter(order =>
        Object.keys(order).some(
          key =>
            typeof order[key] === 'string' &&
            order[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    });
  };

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }));
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  handleDeleteOrder = order => {
    const { onDeleteOrder } = this.props;
    if (order.id !== undefined) {
      onDeleteOrder(order);
      this.onPaginationPageChange(1);
    }
  };

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };

  handleValidTime = date => {
    const time1 = new Date(date).toLocaleTimeString('en-GB');
    return time1;
  };
  handleValidRefundDate = date => {
    const date1 = new Date(date).toLocaleDateString('en-GB');
    return date1;
  };
  handleNext = () => {
    const {
      arr_timeStamp,
      fistDocs,
      businessPoints,
      dateRange,
      transactionStatus,
      dispenseStatus,
      payment,
      currentPage,
      totalPages,
      lastDocs,
    } = this.state;
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
    if (!arr_timeStamp.includes(fistDocs)) {
      arr_timeStamp.push(fistDocs);
    }

    const payload = {
      businessPoints: businessPoints.value,
      dateRange: dateRange,
      transactionStatus: transactionStatus.value,
      dispenseStatus: dispenseStatus.value,
      bank: payment.value,
      lastDocs: lastDocs,
      lastDocsPrev: fistDocs,
      typeButton: 'next',
      arr_timeStamp: arr_timeStamp,
      page: currentPage,
      tags: this.state.tags.value,
    };

    this.props.LoadNextList(payload);
    this.setState({ loading: true });
  };

  handlePrev = () => {
    const {
      arr_timeStamp,
      fistDocs,
      businessPoints,
      dateRange,
      transactionStatus,
      dispenseStatus,
      payment,
      currentPage,
      totalPages,
      lastDocs,
      lastDocsPrev,
    } = this.state;

    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: dateRange,
      transactionStatus: transactionStatus.value,
      dispenseStatus: dispenseStatus.value,
      bank: payment.value,
      fistDocs: fistDocs,
      lastDocsPrev: lastDocsPrev,
      page: currentPage - 1,
      arr_timeStamp: arr_timeStamp,
      tags: this.state.tags.value,
      typeButton: 'prev',
    };
    if (currentPage - 1 == 1) {
      this.setState({ arr_timeStamp: [] });
      this.props.LoadTransactionList(payload);
    } else {
      this.props.LoadNextList(payload);
    }
    this.setState({ loading: true });
  };

  handleBackFirst = () => {
    const {
      arr_timeStamp,
      fistDocs,
      businessPoints,
      dateRange,
      transactionStatus,
      dispenseStatus,
      payment,
      currentPage,
      totalPages,
      lastDocs,
      lastDocsPrev,
    } = this.state;

    this.setState({ currentPage: 1 });
    const payload = {
      businessPoints: businessPoints.value,
      dateRange: dateRange,
      transactionStatus: transactionStatus.value,
      dispenseStatus: dispenseStatus.value,
      bank: payment.value,
      fistDocs: fistDocs,
      lastDocsPrev: lastDocsPrev,
      page: currentPage - 1,
      arr_timeStamp: arr_timeStamp,
      tags: this.state.tags.value,
      typeButton: 'prev',
    };
    this.setState({ arr_timeStamp: [] });
    this.props.LoadTransactionList(payload);
    this.setState({ loading: true });
  };

  handleDownload(name) {
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      transactionStatus: this.state.transactionStatus.value,
      dispenseStatus: this.state.dispenseStatus.value,
      bank: this.state.payment.value,
      name: name,
      tags: this.state.tags.value,
    };
    this.setState({ reportName: name });
    if (APPLICATION_ID == '1000000265' || APPLICATION_ID == '1000000276') {
      this.toggleDownload();
    } else {
      this.setState({ loading: true, isDownload: true });
      // this.toggleLoadingDownload();
      this.props.DownloadData(payload);
      // this.setState({ loading: true, modalLoadingDownload:true });
    }
  }

  render() {
    // console.log(this.props.test)
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Transaction', 'guest'], 'off');
    const tagsList = safeDeepGet(this.props, ['tagsResult', 'body'], []);
    // TODO Loading
    const date = new Date();
    const twoDay = new Date(date.setDate(date.getDate() - REFUND_PERIOD));
    var one_day = 1000 * 60;
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );
    const length = localStorage.getItem('lenghtAllData');
    const { role, refundStatus, lenghtAllData } = this.state;

    let {
      dateRange,
      businessPoints,
      transactionStatus,
      dispenseStatus,
      payment,
      loadingData,
      body,
      currentPage,
      tags,
      isDownload,
      // report_list,
    } = this.state;

    // let list = [];
    let list = safeDeepGet(this.props, ['result', 'body'], []);
    {
      APPLICATION_ID == '1000000159' ||
      APPLICATION_ID == '1000000211' ||
      APPLICATION_ID == '1000000226'
        ? role == 'refiller'
          ? []
          : (list = safeDeepGet(this.props, ['result', 'body'], []))
        : (list = safeDeepGet(this.props, ['result', 'body'], []));
    }

    //pagination customization
    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(Order),
      custom: true,

      onPageChange: (page, sizePerPage) => {
        this.dataChange();
      },
    };

    const lengthData = list.length;

    let defaultSorted = [
      {
        // dataField: 'id',
        dataField: 'time',
        order: 'desc',
      },
    ];

    const txtRole = localStorage.getItem('role');
    const report_list = getFormatReport(list);
    let report_filename = '';
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    var endDate = new Date();
    var startDate = new Date();
    if (dateRange[1] == null) {
      startDate.setDate(1);
      report_filename =
        PROJECT_HANDLE +
        '_Trx ' +
        bp_name +
        ' ' +
        startDate.toLocaleDateString('en-GB') +
        ' ke ' +
        endDate.toLocaleDateString('en-GB');
    } else {
      report_filename =
        PROJECT_HANDLE +
        '_Trx ' +
        bp_name +
        ' ' +
        dateRange[0].toLocaleDateString('en-GB') +
        ' ke ' +
        endDate.toLocaleDateString('en-GB');
    }
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let tag = params.get('tags');
    if (tag != undefined || (tag != null && tag != 'null')) {
      tags = tag;
    } else {
      tags = tags.value ?? '';
    }
    let name = params.get('device_id');
    let trx = params.get('trx_status');
    if (name != undefined || name != null) {
      businessPoints = name;
    } else {
      businessPoints = businessPoints.value;
    }
    if (trx != undefined || trx != null) {
      transactionStatus = trx;
    } else {
      transactionStatus = transactionStatus.value;
    }
    let dsp = params.get('dsp_status');
    let bank = params.get('bank_status');
    if (dsp != undefined || dsp != null) {
      dispenseStatus = dsp;
    } else {
      dispenseStatus = dispenseStatus.value;
    }
    if (bank != undefined || bank != null) {
      payment = bank;
    } else {
      payment = payment.value;
    }
    let start = params.get('dateStart')?.replace('#', '');
    let end = params.get('dateEnd')?.replace('#', '');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    if (start != undefined || start != null) {
      dateRange = [dateStart, dateEnd];
    } else {
      dateRange = dateRange;
    }
    const obj = JSON.parse(localStorage.getItem('role'));
    const { SearchBar } = Search;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Transaction')}</title>
          </MetaTags>
          {!permision ||
          safeDeepGet(navbar, ['Transaction', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid wait={3000}>
              <Breadcrumbs title="Transaction" breadcrumbItem="List" />
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions || [])}
                        keyField="id"
                        columns={this.state.trxColumn}
                        data={this.state.body ?? []}
                        // data={list}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={this.state.body ?? []}
                            // data={list}
                            columns={this.state.trxColumn}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="12" className="d-flex gap-3">
                                    <FormGroup className="ajax-select select2-container flex-grow-1">
                                      <Select
                                        defaultValue={bpfilterlist[0]}
                                        value={bpfilterlist.filter(
                                          option =>
                                            option.value ===
                                            businessPoints.replace('#', '')
                                        )}
                                        onChange={this.handleBusinessPoints}
                                        options={bpfilterlist}
                                        classNamePrefix="select2-selection"
                                        isLoading={false}
                                        placeholder="Business Point"
                                      />
                                    </FormGroup>
                                    <FormGroup className="ajax-select select2-container flex-grow-1">
                                      <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={paymentOptions[0]}
                                        isDisabled={
                                          safeDeepGet(
                                            navbar,
                                            ['Transaction', 'settlement'],
                                            'off'
                                          ) === 'on'
                                        }
                                        isSearchable={false}
                                        onChange={this.handleTransactionStatus}
                                        value={paymentOptions.filter(
                                          option =>
                                            option.value ===
                                            transactionStatus.replace('#', '')
                                        )}
                                        name="color"
                                        options={paymentOptions}
                                        placeholder="Payment Status"
                                      />
                                    </FormGroup>
                                    <FormGroup className="ajax-select select2-container flex-grow-1">
                                      <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={dispenseOptions[0]}
                                        isSearchable={false}
                                        onChange={this.handleDispenseStatus}
                                        value={dispenseOptions.filter(
                                          option =>
                                            option.value ===
                                            dispenseStatus.replace('#', '')
                                        )}
                                        name="color"
                                        options={dispenseOptions}
                                        placeholder="Dispense Status"
                                      />
                                    </FormGroup>
                                    <FormGroup className="ajax-select select2-container flex-grow-1">
                                      <Row>
                                        <Col sm="3">
                                          <Label className="col-md-12 col-form-label text-sm-end">
                                            Date Range
                                          </Label>
                                        </Col>
                                        <Col sm="8">
                                          <FormGroup className="mb-3 ajax-select select2-container">
                                            <InputGroup className="gap-2">
                                              {/* <Flatpickr
                                              className="form-control d-block"
                                              placeholder="dd M,yyyy"
                                              options={{
                                                enableTime: true,
                                                // maxDate: new Date(),
                                                mode: 'range',
                                                dateFormat: 'Y-m-d H:i',
                                                time_24hr: true,
                                              }}
                                              value={dateRange}
                                              onChange={this.handleDateRange}
                                              // ref={this.refDatePicker}
                                              ref={flatpickr =>
                                                (this.flatpickrInstance =
                                                  flatpickr &&
                                                  flatpickr.flatpickr)
                                              }
                                            />
                                          */}
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Start Date"
                                                options={{
                                                  enableTime: true,
                                                  dateFormat: 'Y-m-d H:i',
                                                  time_24hr: true,
                                                }}
                                                value={this.state.startDate}
                                                onChange={date =>
                                                  this.handleStartDateChange(
                                                    date[0]
                                                  )
                                                }
                                                ref={flatpickr =>
                                                  (this.flatpickrStartInstance =
                                                    flatpickr?.flatpickr)
                                                }
                                              />{' '}
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="End Date"
                                                options={{
                                                  enableTime: true,
                                                  dateFormat: 'Y-m-d H:i',
                                                  time_24hr: true,
                                                }}
                                                value={this.state.endDate}
                                                onChange={date =>
                                                  this.handleEndDateChange(
                                                    date[0]
                                                  )
                                                } // Auto Submit setelah pilih End Date
                                                ref={flatpickr =>
                                                  (this.flatpickrEndInstance =
                                                    flatpickr?.flatpickr)
                                                }
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="mb-2 d-flex align-items-center gap-3">
                                  {/* Select Payment Method */}
                                  <Col sm="8" className="d-flex gap-3">
                                    <FormGroup className="ajax-select select2-container flex-grow-1">
                                      <Select
                                        defaultValue={bankOptions[0]}
                                        value={bankOptions.filter(
                                          option =>
                                            option.value ===
                                            payment.replace('#', '')
                                        )}
                                        onChange={this.handlePaymentMethod}
                                        options={bankOptions}
                                        classNamePrefix="select2-selection"
                                        isLoading={false}
                                        placeholder="Payment Method"
                                      />
                                    </FormGroup>
                                    <FormGroup className="ajax-select select2-container flex-grow-1">
                                      <Select
                                        defaultValue={tagsList[0]}
                                        value={tagsList.filter(
                                          option =>
                                            option.value ===
                                            tags.replace('#', '')
                                        )}
                                        onChange={this.handleTags}
                                        options={tagsList}
                                        classNamePrefix="select2-selection"
                                        isLoading={false}
                                        placeholder="Tag Filter"
                                      />
                                    </FormGroup>
                                    <FormGroup className="ajax-select select2-container flex-grow-1">
                                      <div className="search-box position-relative">
                                        <input
                                          type="text"
                                          placeholder="Search Order ID"
                                          value={this.state.searchTerm}
                                          onChange={this.handleSearchChange}
                                          style={{
                                            borderRadius: '20px',
                                            padding: '5px',
                                            width: '100%',
                                          }}
                                        />
                                      </div>
                                    </FormGroup>
                                    <FormGroup className="ajax-select select2-container flex-grow-1">
                                      {this.state.loading ? (
                                        <div
                                          className="loading-overlay d-flex align-items-center justify-content-center"
                                          style={{
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            width: '100vw',
                                            height: '100vh',
                                            backgroundColor:
                                              'rgba(0, 0, 0, 0.5)', // Backdrop transparan
                                            zIndex: 9999, // Pastikan modal di atas semuanya
                                          }}
                                        >
                                          {isDownload ? (
                                            <div
                                              style={{
                                                width: '400px',
                                                height: '200px',
                                                borderRadius: '20px',
                                                backgroundColor: 'white',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'space-evenly',
                                                border: '1px solid #ccc',
                                                padding: '20px',
                                                textAlign: 'center',
                                                boxShadow:
                                                  '0px 4px 10px rgba(0,0,0,0.3)',
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  color: 'black',
                                                  margin: 0,
                                                }}
                                              >
                                                Downloading...
                                              </h3>
                                              <Progress
                                                color="gray"
                                                style={{
                                                  width: '90%',
                                                  height: '15px',
                                                  borderRadius: '10px',
                                                  border: '2px solid gray',
                                                }}
                                                animated
                                                value={this.state.progress}
                                              >
                                                {this.state.progress}%
                                              </Progress>
                                            </div>
                                          ) : (
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="120"
                                              height="120"
                                              viewBox="0 0 100 100"
                                              preserveAspectRatio="xMidYMid"
                                              className="lds-ring"
                                            >
                                              <circle
                                                cx="50"
                                                cy="50"
                                                r="40"
                                                stroke="#fff"
                                                strokeWidth="10"
                                                strokeDasharray="62.83185307179586 62.83185307179586"
                                                fill="none"
                                                strokeLinecap="round"
                                                transform="rotate(215.582 50 50)"
                                              >
                                                <animateTransform
                                                  attributeName="transform"
                                                  type="rotate"
                                                  calcMode="linear"
                                                  values="0 50 50;360 50 50"
                                                  keyTimes="0;1"
                                                  dur="1s"
                                                  begin="0s"
                                                  repeatCount="indefinite"
                                                ></animateTransform>
                                              </circle>
                                            </svg>
                                          )}
                                        </div>
                                      ) : (
                                        <ButtonDownload
                                          list={list}
                                          onClick={() =>
                                            this.handleDownload(report_filename)
                                          }
                                          report_filename={report_filename}
                                          selectedMonthYear={
                                            this.state.selectedMonthYear
                                          }
                                          businessPoints={businessPoints}
                                          dateRange={dateRange}
                                          isLoading={this.state.loading}
                                        />
                                      )}
                                    </FormGroup>
                                  </Col>
                                  {/* Info Jumlah Data */}
                                  <Col sm="3" className="text-end">
                                    <p style={{ fontSize: 13 }}>
                                      Menampilkan{' '}
                                      {safeDeepGet(
                                        this.state,
                                        'lenghtTable',
                                        list.length < 25 ? list.length : 25
                                      )}{' '}
                                      dari {lengthData} transaksi
                                    </p>
                                  </Col>
                                </Row>

                                <div className="table-responsive">
                                  <ReactTooltip place="top" effect="solid" />

                                  <BootstrapTable
                                    // onDataSizeChange={this.dataChange}
                                    // onTableChange={props =>this.dataChange(props)}
                                    // onDataSizeChange= {props => this.dataChange(props.dataSize)}
                                    // onTableChange={(newState)=> this.dataChange(newState)}
                                    onTableChange={this.handleTableChange}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      'table align-middle table-nowrap table-check'
                                    }
                                    headerWrapperClasses={'table-light'}
                                    ref={this.node}
                                  />
                                </div>

                                <div
                                  className="justify-content-end mb-2 btm-rounded"
                                  // className="pagination pagination-rounded justify-content-end mb-2"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Pagination
                                    aria-label="Page navigation example"
                                    size="md"
                                  >
                                    {currentPage > 1 ? (
                                      <>
                                        <PaginationItem
                                          disabled={currentPage === 1}
                                        >
                                          <PaginationLink
                                            first
                                            href="#"
                                            onClick={this.handleBackFirst}
                                          >
                                            First
                                          </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem
                                          disabled={currentPage === 1}
                                        >
                                          <PaginationLink
                                            href="#"
                                            previous
                                            onClick={this.handlePrev}
                                          >
                                            Previous
                                          </PaginationLink>
                                        </PaginationItem>
                                      </>
                                    ) : null}

                                    <PaginationItem>
                                      <PaginationLink href="#">
                                        {currentPage}
                                      </PaginationLink>
                                    </PaginationItem>

                                    <PaginationItem
                                      disabled={body.length != PAGE_SIZE}
                                    >
                                      <PaginationLink
                                        href="#"
                                        next
                                        onClick={this.handleNext}
                                      >
                                        Next
                                      </PaginationLink>
                                    </PaginationItem>
                                  </Pagination>
                                </div>
                                {/* <div
                                  className="pagination pagination-rounded justify-content-end mb-2"
                                  >
                                   <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                      <Modal
                        size="xl"
                        isOpen={this.state.modalDetail}
                        className={this.props.className}
                        toggle={this.toggleDetail}
                      >
                        <div className="modal-header">
                          <h4
                            className="modal-title mt-0"
                            id="exampleModalFullscreenLabel"
                          >
                            {this.state.trx.detail != undefined
                              ? 'Order ID ' + this.state.trx.detail.order_id
                              : null}
                          </h4>
                          <button
                            onClick={() =>
                              this.setState({ modalDetail: false })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        {this.state.trx.detail != undefined ? (
                          <ModalBody>
                            <h5 className="mb-4">
                              Business Point :{' '}
                              <span className="text-primary">
                                {this.state.trx.name}
                              </span>
                            </h5>
                            <h5 className="mb-4">
                              Date :{' '}
                              <span className="text-primary">
                                {' '}
                                {this.handleValidDate(
                                  this.state.trx.time.firestore_timestamp
                                )}
                              </span>
                            </h5>

                            <div className="table-responsive mb-2">
                              <Table className="table align-middle table-nowrap">
                                <thead>
                                  <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Payment Status</th>
                                    <th scope="col">Dispense Status</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">
                                      {' '}
                                      {this.state.trx.payment.method == 'RFID'
                                        ? 'RFID'
                                        : 'Issuer'}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">
                                      <div>
                                        <h5 className="text-truncate font-size-14">
                                          {APPLICATION_ID == '1000000209'
                                            ? this.state.trx.nameProduct
                                            : this.state.trx.product.sku}
                                        </h5>
                                      </div>
                                    </th>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14">
                                          {safeDeepGet(
                                            this.state.trx,
                                            ['detail', 'transaction_status'],
                                            '-'
                                          ) === 'refunded' ? (
                                            <span
                                              style={{ color: 'red' }}
                                              className="mt-1"
                                            >
                                              {'-' +
                                                safeDeepGet(
                                                  this.state.trx,
                                                  ['payment', 'amount'],
                                                  '-'
                                                )}
                                            </span>
                                          ) : (
                                            <span className="mt-1">
                                              {safeDeepGet(
                                                this.state.trx,
                                                ['payment', 'amount'],
                                                '-'
                                              )}
                                            </span>
                                          )}
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <span
                                          style={{ border: 'none' }}
                                          className={
                                            'font-size-12 badge badge-pill badge-soft-' +
                                            this.state.trx.badgeclass
                                          }
                                          color={this.state.trx.badgeclass}
                                        >
                                          {
                                            this.state.trx.detail
                                              .transaction_status
                                          }
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <span
                                          style={{ border: 'none' }}
                                          className={
                                            'font-size-12 badge badge-pill badge-soft-' +
                                            this.state.trx.badge
                                          }
                                          color={this.state.trx.badge}
                                        >
                                          {
                                            this.state.trx.detail
                                              .dispense_status
                                          }
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14">
                                          {this.state.trx.payment.method}
                                        </h5>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <h5 className="text-truncate font-size-14">
                                          {this.state.trx.payment.method ==
                                          'RFID'
                                            ? this.state.trx.payment.detail.rfid
                                            : this.state.trx.payment.detail
                                                .issuer}
                                        </h5>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className="mb-4">
                              <Row>
                                <Col sm={1}>
                                  <div className="text-center">
                                    {' '}
                                    {this.state.trx.detail.transaction_status ==
                                    'settlement' ? (
                                      this.state.trx.time.timestamp >=
                                      twoDay ? (
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-sm btn-rounded btn-success"
                                          onClick={() =>
                                            this.handleRefundedMidTrans(
                                              this.state.trx
                                            )
                                          }
                                        >
                                          Refund
                                        </Button>
                                      ) : (
                                        ' '
                                      )
                                    ) : this.state.trx.detail
                                        .transaction_status == 'refunded' ? (
                                      <p className="mb-0">Refunded on</p>
                                    ) : (
                                      ' '
                                    )}
                                  </div>
                                </Col>
                                <Col sm={11}>
                                  <div className="text-start">
                                    <p
                                      style={{ fontWeight: 'bold' }}
                                      className="mt-1"
                                    >
                                      {this.state.trx.detail
                                        .transaction_status == 'settlement' ? (
                                        this.state.trx.time.timestamp >=
                                        twoDay ? (
                                          'Time remaining for Refund ' +
                                          this.timeConvert(
                                            Math.ceil(
                                              (this.state.trx.time.timestamp -
                                                twoDay) /
                                                one_day
                                            )
                                          )
                                        ) : (
                                          ' '
                                        )
                                      ) : this.state.trx.detail
                                          .transaction_status == 'refunded' ? (
                                        <>
                                          <p className="mb-0">
                                            {this.handleValidRefundDate(
                                              this.state.trx.detail.refund_time
                                            ) + ' '}{' '}
                                            {this.handleValidTime(
                                              this.state.trx.detail.refund_time
                                            )}
                                          </p>
                                          <p className="mb-1"></p>
                                        </>
                                      ) : (
                                        ' '
                                      )}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </ModalBody>
                        ) : null}
                      </Modal>
                      <Modal
                        size="lg"
                        isOpen={this.state.modal}
                        className={this.props.className}
                      >
                        <ModalHeader toggle={() => this.toggle()} tag="h4">
                          {'Refund Order ID ' + this.state.products.id}
                        </ModalHeader>
                        <ModalBody>
                          <AvForm onValidSubmit={this.handleValidRefundSubmit}>
                            <Row form>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <AvField
                                    name="amount"
                                    label="Jumlah Refund"
                                    type="number"
                                    errorMessage="Invalid amount"
                                    validate={{
                                      min: { value: 0.01 },
                                      max: {
                                        value: this.state.products.amount,
                                      },
                                    }}
                                    disabled={true}
                                    value={this.state.products.amount || ''}
                                  />
                                </div>
                                <p>
                                  {'*Batas minimum refund Rp. 0.01.- dan Max Rp. ' +
                                    this.state.products.amount +
                                    '.-'}
                                </p>
                                <p>
                                  {
                                    '**Jika metode pembayaran CASH pastikan sudah melakukan Refund manual ke pelanggan'
                                  }
                                </p>
                                <div className="mb-3">
                                  <AvField
                                    name="reason"
                                    label="Masukan alasan refund.."
                                    type="text"
                                    disabled={false}
                                    value={''}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  {guest == 'off' ? (
                                    <button
                                      type="submit"
                                      className="btn btn-success save-user"
                                      disabled={this.state.products.button}
                                    >
                                      Submit
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </ModalBody>
                      </Modal>

                      <Modal
                        size="md"
                        centered={true}
                        isOpen={this.state.modalDownload}
                        className={this.props.className}
                        backdrop="static"
                      >
                        <ModalHeader
                          toggle={() => this.toggleDownload()}
                          tag="h4"
                        >
                          {'Monthly fee'}
                        </ModalHeader>
                        <ModalBody>
                          <div>
                            <AvForm
                              onValidSubmit={this.handleValidSubmitMonthly}
                            >
                              <Row form>
                                <Col className="col-12">
                                  <div className="mb-3">
                                    <AvField
                                      name="internet"
                                      label="Jumlah Internet"
                                      type="number"
                                      errorMessage="Invalid amount"
                                      validate={{
                                        min: { value: 0.01 },
                                      }}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <AvField
                                      name="listrik"
                                      label="Jumlah Listrik"
                                      type="number"
                                      errorMessage="Invalid amount"
                                      validate={{
                                        min: { value: 0.01 },
                                      }}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <AvField
                                      name="promo"
                                      label="Jumlah Promo"
                                      type="number"
                                      errorMessage="Invalid amount"
                                      validate={{
                                        min: { value: 0.01 },
                                      }}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <AvField
                                      name="maintenance"
                                      label="Jumlah Pemeliharaan"
                                      type="number"
                                      errorMessage="Invalid amount"
                                      validate={{
                                        min: { value: 0.01 },
                                      }}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <AvField
                                      name="landlord"
                                      label="Jumlah Sewa Tempat *Jika Ada"
                                      type="number"
                                      errorMessage="Invalid amount"
                                      validate={{
                                        min: { value: 0.01 },
                                      }}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <AvField
                                      name="dll"
                                      label="Jumlah Lainnya"
                                      type="number"
                                      errorMessage="Invalid amount"
                                      validate={{
                                        min: { value: 0.01 },
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      type="submit"
                                      className="btn btn-success save-user"
                                      disabled={this.state.products.button}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </AvForm>
                          </div>
                        </ModalBody>
                      </Modal>

                      <Modal
                        size="md"
                        centered={true}
                        isOpen={this.state.modalLoadingDownload}
                        className={this.props.className}
                        backdrop="static"
                      >
                        <ModalBody>
                          <div>
                            <Row
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <h4
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                {'Loading Data....'}
                              </h4>
                              <BarLoader
                                color="#123abc"
                                loading={loadingData}
                                size={150}
                              />
                            </Row>
                          </div>
                        </ModalBody>
                      </Modal>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  test: state,
  bpfilterresult: safeDeepGet(
    state,
    ['transaction', 'list_business_points'],
    {}
  ),
  result: safeDeepGet(state, ['transaction', 'list'], {}),
  resultNext: safeDeepGet(state, ['transaction', 'nextList'], {}),
  resultDownload: safeDeepGet(state, ['transaction', 'download_data'], {}),
  resultSearch: safeDeepGet(state, ['transaction', 'search_data'], {}),
  loading: safeDeepGet(state, ['transaction', 'loading'], true),
  progressResult: safeDeepGet(
    state,
    ['transaction', 'update_download_prgress'],
    {}
  ),
  tagsResult: safeDeepGet(state, ['tag', 'list_tags'], {}),
});
Transaction.propTypes = {
  onRefund: PropTypes.func,
};
const mapDispatchToProps = dispatch => ({
  LoadBpFilterList: payload => dispatch(list_business_points(payload)),
  LoadNextList: (
    businessPoints,
    transactionStatus,
    dispenseStatus,
    dateRange,
    data,
    lastDocs,
    fistDocs,
    typeButton,
    lastDocsPrev,
    page,
    arr_timeStamp
  ) =>
    dispatch(
      nextList(
        businessPoints,
        transactionStatus,
        dispenseStatus,
        dateRange,
        data,
        lastDocs,
        fistDocs,
        typeButton,
        lastDocsPrev,
        page,
        arr_timeStamp
      )
    ),
  LoadBusinessPointFillter: payload => dispatch(list_business_points(payload)),
  LoadTransactionList: (
    businessPoints,
    transactionStatus,
    dispenseStatus,
    dateRange,
    bank,
    tags
  ) =>
    dispatch(
      list(
        businessPoints,
        transactionStatus,
        dispenseStatus,
        dateRange,
        bank,
        tags
      )
    ),
  ReadTransaction: transactionId => dispatch(read(transactionId)),
  RefundTransaction: payload => dispatch(updateRefund(payload)),
  onRefund: refund => dispatch(refunded(refund)),
  DownloadData: (
    businessPoints,
    transactionStatus,
    dispenseStatus,
    dateRange,
    data,
    bank,
    cost,
    tags
  ) =>
    dispatch(
      download_data(
        businessPoints,
        transactionStatus,
        dispenseStatus,
        dateRange,
        data,
        bank,
        cost,
        tags
      )
    ),

  SearchData: payload => dispatch(search_data(payload)),
  ListTags: () => dispatch(list_tags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
