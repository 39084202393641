/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
} from './actionTypes';

import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet, safeDel } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
// import { createdUser, deletedUser } from 'Apps/device';

function* list() {
  yield put(loading(LIST));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.role.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const roleBody = safeDeepGet(wsResult, 'body', {});
    const roleList = [];
    wsResult = yield call(firebaseInstance.businessPoint.list);
    const bpBody = safeDeepGet(wsResult, 'body');
    for (const role in roleBody) {
      const bpMap = safeDeepGet(roleBody, [role, 'businessPoint'], {});
      const bpList = [];
      for (const bp in bpMap) {
        const device_id = safeDeepGet(bpMap[bp], 'device_id', '');
        const deviceName = safeDeepGet(bpBody, [device_id, 'name'], bp);
        safeDeepSet(bpMap[bp], 'name', deviceName);
        bpList.push(bpMap[bp]);
      }
      const navMap = safeDeepGet(roleBody, [role, 'navigation'], {});
      const navList = [];
      for (const nav in navMap) {
        safeDeepSet(navMap[nav], 'name', nav);
        navList.push(navMap[nav]);
      }
      safeDel(roleBody[role], 'businessPoint');
      safeDel(roleBody[role], 'navigation');
      safeDeepSet(roleBody[role], 'businessPoint', bpList);
      safeDeepSet(roleBody[role], 'navigation', navList);
      roleList.push(roleBody[role]);
    }
    yield put(result(LIST, { code: Result.SUCCESS, body: roleList }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* initCreate() {
  yield put(loading(INIT_CREATE));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.navigation.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS) {
    const dataBody = safeDeepGet(wsResult, ['body', 'data'], {});
    const dataList = [];
    const bpList = [];
    let i = 1;
    for (const data in dataBody) {
      const edit = safeDeepGet(dataBody, [data, 'edit'], 'off');
      const view = safeDeepGet(dataBody, [data, 'view'], 'off');
      let access = 'No_access';
      if (view == 'on' && edit == 'on') {
        access = 'Edit';
      } else if (view == 'on' && edit == 'off') {
        access = 'View';
      }
      safeDeepSet(dataBody, [data, 'access'], access);
      safeDeepSet(dataBody, [data, 'name'], data);
      safeDeepSet(dataBody, [data, 'id'], i++);
      dataList.push(dataBody[data]);
    }
    wsResult = yield call(firebaseInstance.businessPoint.list);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (code == Result.SUCCESS) {
      const bpBody = safeDeepGet(wsResult, 'body', {});
      for (const bp in bpBody) {
        const name = safeDeepGet(bpBody, [bp, 'name'], '');
        const device_id = safeDeepGet(bpBody, [bp, 'device_id'], '');
        bpList.push({ name, device_id, active: 'on' });
      }
      yield put(
        result(INIT_CREATE, {
          code: Result.SUCCESS,
          body: dataList,
          bpList: bpList,
        })
      );
    } else {
      yield put(result(INIT_CREATE, { code: Result.FAILED, body: [] }));
    }
  } else {
    yield put(result(INIT_CREATE, { code: Result.FAILED, body: [] }));
  }
}
function* create(action) {
  yield put(loading(CREATE));
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const bp = safeDeepGet(payload, 'businessPoint');
  const useTag = safeDeepGet(payload, 'useTag');
  if (useTag) {
    safeDeepSet(payload, 'allBp', 'true');
  }
  const bpMap = {};
  for (const i in bp) {
    const name = safeDeepGet(bp[i], 'name');
    safeDel(bp[i], 'name');
    bpMap[name] = bp[i];
  }
  safeDeepSet(payload, 'bpMap', bpMap);
  const nav = safeDeepGet(payload, 'navigation');
  const navMap = {};
  for (const i in nav) {
    const access = safeDeepGet(nav[i], 'access');
    var edit = 'off';
    var view = 'off';
    var settlement = 'off';
    var landlord = 'off';
    var guest = 'off';
    if (access === 'Edit') {
      edit = 'on';
      view = 'on';
    } else if (access === 'View') {
      view = 'on';
    } else if (access === 'View Settlement' || access === 'View_Settlement') {
      view = 'on';
      settlement = 'on';
    } else if (access === 'View_Landlord') {
      view = 'on';
      landlord = 'on';
      edit = 'on';
    } else if (access == 'Guest') {
      guest = 'on';
      view = 'on';
      edit = 'on';
    }
    safeDeepSet(nav[i], 'view', view);
    safeDeepSet(nav[i], 'edit', edit);
    safeDeepSet(nav[i], 'landlord', landlord);
    safeDeepSet(nav[i], 'settlement', settlement);
    safeDeepSet(nav[i], 'guest', guest);

    const name = safeDeepGet(nav[i], 'name');
    safeDel(nav[i], 'name');
    safeDel(nav[i], 'access');
    // safeDeepSet(navMap, [name, 'access'], access);
    navMap[name] = nav[i];
  }
  safeDeepSet(payload, 'navMap', navMap);
  const wsResult = yield call(firebaseInstance.role.create, payload);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  yield put(result(CREATE, { code }));
}
function* list_vm() {
  yield put(loading(LIST_VM));
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.role.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const listMap = wsResult.body;
    const refillerList = [];
    for (const id in listMap) {
      let refillerName = safeDeepGet(listMap, [id, 'name']);
      let refillerId = safeDeepGet(
        listMap,
        [id, 'code'],
        safeDeepGet(listMap, [id, 'id'], '')
      );
      refillerList.push({ label: refillerName, value: refillerId });
    }
    yield put(result(LIST_VM, { code: Result.SUCCESS, body: refillerList }));
    return;
  }
  yield put(result(LIST_VM, { result: { code: Result.FAILED } }));
}
function* read(action) {
  yield put(loading(READ));
  const id = safeDeepGet(action, ['payload', 'id']);
  const firebaseInstance = getInstance();
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  let code = safeDeepGet(wsResult, 'code', Result.FAILED);
  let bpBody = {};
  if (code == Result.SUCCESS) {
    bpBody = safeDeepGet(wsResult, 'body', {});
  }
  wsResult = yield call(firebaseInstance.role.read, id);
  const body = wsResult.body;
  const bpMap = safeDeepGet(body, 'businessPoint', {});
  const bpList = [];
  for (const bp in bpMap) {
    const device_id = safeDeepGet(bpMap[bp], 'device_id');
    safeDeepSet(bpMap[bp], 'name', bp);
    bpMap[device_id] = bpMap[bp];
    safeDel(bpMap, bp);
  }
  for (const bp in bpBody) {
    const active = safeDeepGet(bpMap, [bp, 'active'], 'off');
    const name = safeDeepGet(bpBody, [bp, 'name'], '');
    const device_id = safeDeepGet(bpBody, [bp, 'device_id'], '');
    bpList.push({ name, device_id, active });
  }
  const navMap = safeDeepGet(body, 'navigation', {});
  const navList = [];

  wsResult = yield call(firebaseInstance.navigation.list);
  code = safeDeepGet(wsResult, 'code', Result.FAILED);
  const dataBody = safeDeepGet(wsResult, ['body', 'data'], {});
  const finalData = mergeData(dataBody, navMap);

  for (const nav in finalData) {
    const edit = safeDeepGet(finalData, [nav, 'edit'], 'off');
    const view = safeDeepGet(finalData, [nav, 'view'], 'off');
    const settlement = safeDeepGet(finalData, [nav, 'settlement'], 'off');
    const landlord = safeDeepGet(finalData, [nav, 'landlord'], 'off');
    const guest = safeDeepGet(finalData, [nav, 'guest'], 'off');
    let access = 'No_access';
    if (view == 'on' && edit == 'on') {
      if (landlord == 'on') {
        access = 'View_Landlord';
      } else if (guest == 'on') {
        access = 'Guest';
      } else {
        access = 'Edit';
      }
    } else if (view == 'on' && settlement == 'on') {
      access = 'View_Settlement';
    } else if (view == 'on' && edit == 'off') {
      access = 'View';
    }
    safeDeepSet(finalData[nav], 'access', access);
    safeDeepSet(finalData[nav], 'name', nav);
    navList.push(finalData[nav]);
  }

  safeDel(body, 'businessPoint');
  safeDel(body, 'navigation');
  safeDeepSet(body, 'businessPoint', bpList);
  safeDeepSet(body, 'navigation', navList);
  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }));
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: body,
    })
  );
}

function mergeData(baseData, userData) {
  let mergedData = {};

  Object.keys(baseData).forEach(key => {
    // Jika data sudah ada di userData, gunakan itu
    if (userData[key]) {
      mergedData[key] = { ...baseData[key], ...userData[key] };
    } else {
      // Jika data belum ada di userData, tambahkan dari baseData
      mergedData[key] = baseData[key];
    }
  });

  return mergedData;
}

function* update(action) {
  yield put(loading(UPDATE));
  const id = safeDeepGet(action, ['payload', 'id']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();
  const bp = safeDeepGet(payload, 'businessPoint');
  const useTag = safeDeepGet(payload, 'useTag');
  if (useTag) {
    safeDeepSet(payload, 'allBp', 'true');
  }
  const bpMap = {};
  for (const i in bp) {
    const name = safeDeepGet(bp[i], 'name');
    safeDel(bp[i], 'name');
    bpMap[name] = bp[i];
  }
  safeDeepSet(payload, 'bpMap', bpMap);
  const nav = safeDeepGet(payload, 'navigation');
  const navMap = {};
  for (const i in nav) {
    const access = safeDeepGet(nav[i], 'access');
    var edit = 'off';
    var view = 'off';
    var settlement = 'off';
    var landlord = 'off';
    var guest = 'off';
    if (access === 'Edit') {
      edit = 'on';
      view = 'on';
    } else if (access === 'View') {
      view = 'on';
    } else if (access === 'View Settlement' || access === 'View_Settlement') {
      view = 'on';
      settlement = 'on';
    } else if (access === 'View_Landlord') {
      view = 'on';
      landlord = 'on';
      edit = 'on';
    } else if (access == 'Guest') {
      view = 'on';
      edit = 'on';
      guest = 'on';
    }
    safeDeepSet(nav[i], 'view', view);
    safeDeepSet(nav[i], 'edit', edit);
    safeDeepSet(nav[i], 'landlord', landlord);
    safeDeepSet(nav[i], 'settlement', settlement);
    safeDeepSet(nav[i], 'guest', guest);

    const name = safeDeepGet(nav[i], 'name');
    safeDel(nav[i], 'name');
    safeDel(nav[i], 'access');
    // safeDeepSet(navMap, [name, 'access'], access);
    navMap[name] = nav[i];
  }
  safeDeepSet(payload, 'navMap', navMap);
  const wsResult = yield call(firebaseInstance.role.update, id, payload);
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(UPDATE, { code }));
}

function* stockUpdate(action) {
  yield put(loading(STOCK_UPDATE));
  const sku = safeDeepGet(action, ['payload', 'sku']);
  const payload = safeDeepGet(action, 'payload');
  const firebaseInstance = getInstance();

  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ['payload', 'imageFile']);
  let imageUrl = null;
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ['payload', 'imageExt']);
    const wsResult = yield call(
      firebaseInstance.productStorage.replace,
      imageFile,
      sku,
      imageExt
    );
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ['body', 'url']);
    }
  }
  if (imageUrl != null) {
    payload['img_url'] = imageUrl;
  }

  const wsResult = yield call(
    firebaseInstance.product.updateStock,
    sku,
    payload
  );
  const code = safeDeepGet(wsResult, 'code');

  yield put(result(STOCK_UPDATE, { code }));
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const id = safeDeepGet(payload, ['payload', 'id']);
  const firebaseInstance = getInstance();
  const wsResult = yield call(firebaseInstance.role.delete_, id);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  yield put(result(DELETE, { code }));
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(READ, read);
  yield takeEvery(UPDATE, update);
  yield takeEvery(STOCK_UPDATE, stockUpdate);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(LIST_VM, list_vm);
}

export default productSaga;
