/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
// Table
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Select from 'react-select';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Row,
} from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Availity
import {
  AvField,
  AvForm,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';

// Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { initCreate, create, read, update } from '../../store/Role/actions';
import { list_tags } from 'Base/store/tag/actions';
import { buildTitle } from 'Apps';
import Pages403 from '../pages-403';
import { NAVBAR, PERMISION } from 'Apps';
const navbar = NAVBAR;
const permision = PERMISION;

class CreateRole extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let role_id = null;
    const path = this.props.match.path;
    if (path.includes('/edit/')) {
      isEdit = true;
      role_id = safeDeepGet(this.props.match.params, 'id');
    }
    this.state = {
      isEdit,
      check: false,
      useTag: 'false',
      shareLandlord: 'false',
      allBp: 'true',
      role_id,
      selectedTag: '',
      body: [],
      tagsOptions: [],
      bpList: [],
      columnBp: [
        {
          dataField: 'name',
          text: 'Name',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return <>{row.name}</>;
          },
        },
        {
          dataField: 'device_id',
          text: 'Device ID',
          editable: false,
          hidden: 'true',
          formatter: (cell, row, rowIndex) => {
            return <>{row.device_id}</>;
          },
        },
        {
          dataField: 'active',
          text: 'Active',
          editor: {
            type: Type.CHECKBOX,
            // value: 'On:Off'
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <>
                <Input
                  type="checkbox"
                  defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
                />
              </>
            );
          },
        },
      ],
      column: [
        {
          dataField: 'id',
          text: 'id',
          hidden: 'true',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return <>{row.id}</>;
          },
        },
        {
          dataField: 'nav',
          text: 'Name',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return <>{row.name}</>;
          },
        },
        {
          dataField: 'access',
          text: 'Access',
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              const options = [
                { label: 'View', value: 'View' },
                { label: 'Edit', value: 'Edit' },
                { label: 'No Access', value: 'No_access' },
                { label: 'Guest', value: 'Guest' },
              ];
              const optionsTrx = [
                { label: 'View', value: 'View' },
                { label: 'View Settlement', value: 'View_Settlement' },
                { label: 'View Landlord', value: 'View_Landlord' },
                { label: 'Edit', value: 'Edit' },
                { label: 'No Access', value: 'No_access' },
                { label: 'Guest', value: 'Guest' },
              ];
              return row.name == 'Transaction' ? optionsTrx : options;
            },
          },
        },
      ],
    };
    this.navRef = React.createRef();
    this.bpRef = React.createRef();
    this.handleChecked = this.handleChecked.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.changeName = this.changeName.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleCheckedTag = this.handleCheckedTag.bind(this);
    this.handleCheckedShareLandlord =
      this.handleCheckedShareLandlord.bind(this);
  }

  handleTagChange(values) {
    this.setState({
      selectedTag: values.value,
    });
  }
  changeName(e, values) {
    const { isEdit } = this.state;
    const text = values.replace(/ /g, '_');
    const id = text.toLowerCase();
    if (!isEdit) {
      this.setState({ id });
    }
  }
  handleCancleClick() {
    this.props.history.push(`/role`);
  }
  handleChecked(e) {
    const value = e.target.value;
    const { bpList } = this.state;
    if (value == 'true') {
      const updatedDevices = bpList.map(device => ({
        ...device,
        active: 'on',
      }));
      this.setState({ check: true, bpList: updatedDevices });
    } else {
      const updatedDevices = bpList.map(device => ({
        ...device,
        active: 'off',
      }));
      this.setState({ check: false, bpList: updatedDevices });
    }
  }
  handleCheckedTag(e) {
    const value = e.target.value;
    if (value == 'true') {
      this.setState({ useTag: 'true' });
    } else {
      this.setState({ useTag: 'false' });
    }
  }

  handleCheckedShareLandlord(e) {
    const value = e.target.value;
    if (value == 'true') {
      this.setState({ shareLandlord: 'true' });
    } else {
      this.setState({ shareLandlord: 'false' });
    }
  }

  handleValidSubmit(event, values) {
    const { isEdit, selectedTag, useTag, shareLandlord } = this.state;
    const nav = this.navRef.current.props.data;
    let submittedValues = {
      ...values,
      useTag: JSON.parse(useTag),
      shareLandlord: JSON.parse(shareLandlord),
      tag: selectedTag,
      businessPoint: [],
      navigation: nav,
    };
    if (useTag == 'false') {
      const bp = this.bpRef.current.props.data;
      submittedValues = {
        ...values,
        useTag: JSON.parse(useTag),
        shareLandlord: JSON.parse(shareLandlord),
        tag: selectedTag,
        businessPoint: bp,
        navigation: nav,
      };
    }
    if (isEdit) {
      this.props.UpdateRole(submittedValues);
    } else {
      this.props.CreateRole(submittedValues);
    }
  }
  componentDidMount() {
    const { isEdit, role_id } = this.state;
    // Load Refiller
    if (!isEdit) {
      this.props.InitCreateRole();
    } else {
      this.props.ReadRole(role_id);
    }
    this.props.GetTags();
  }
  showToast() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'success';
      var message = 'Berhasil Update Role';
    } else {
      var toastType = 'success';
      var message = 'Berhasil Tambah Role';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToastFailed() {
    const { isEdit } = this.state;

    if (isEdit) {
      var toastType = 'error';
      var message = 'Gagal Update Role';
    } else {
      var toastType = 'error';
      var message = 'Gagal Tambah Role';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isEdit } = this.state;

    if (!isEdit) {
      if (this.props.initCreateResult !== prevProps.initCreateResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['initCreateResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code === Result.SUCCESS || code === Result.HTTP.OK) {
          const body = safeDeepGet(
            this.props,
            ['initCreateResult', 'body'],
            []
          );
          const bpList = safeDeepGet(
            this.props,
            ['initCreateResult', 'bpList'],
            []
          );
          this.setState({
            body,
            bpList,
          });
        } else if (code === Result.UNKNOWN_ERROR) {
          const code = safeDeepGet(
            this.props,
            ['initCreateResult', 'code'],
            Result.UNKNOWN_ERROR
          );
        } else {
          this.showToastFailed();
        }
      }
      if (this.props.createResult !== prevProps.createResult) {
        // Success create
        const code = safeDeepGet(
          this.props,
          ['createResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code === Result.SUCCESS || code === Result.HTTP.OK) {
          this.showToast();
          this.props.history.replace('/role');
        } else if (code === Result.UNKNOWN_ERROR) {
          const code = safeDeepGet(
            this.props,
            ['createResult', 'code'],
            Result.UNKNOWN_ERROR
          );
        } else {
          this.showToastFailed();
        }
      }
    } else {
      if (this.props.readResult !== prevProps.readResult) {
        const code = safeDeepGet(
          this.props,
          ['readResult', 'code'],
          Result.UNKNOWN_ERROR
        );
        if (code == Result.SUCCESS) {
          const newState = {};
          const name = safeDeepGet(this.props, ['readResult', 'body', 'name']);
          newState['name'] = name;
          const id = safeDeepGet(this.props, ['readResult', 'body', 'id']);
          newState['id'] = id;
          const businessPoint = safeDeepGet(this.props, [
            'readResult',
            'body',
            'businessPoint',
          ]);
          newState['bpList'] = businessPoint;
          const navigation = safeDeepGet(this.props, [
            'readResult',
            'body',
            'navigation',
          ]);
          newState['body'] = navigation;
          const permision = safeDeepGet(
            this.props,
            ['readResult', 'body', 'permision'],
            'false'
          );
          newState['permision'] = permision;
          const tag = safeDeepGet(
            this.props,
            ['readResult', 'body', 'tag'],
            ''
          );
          newState['selectedTag'] = tag;
          const useTag = safeDeepGet(
            this.props,
            ['readResult', 'body', 'useTag'],
            ''
          );
          if (useTag) {
            newState['useTag'] = 'true';
          } else {
            newState['useTag'] = 'false';
          }

          const shareLandlord = safeDeepGet(
            this.props,
            ['readResult', 'body', 'shareLandlord'],
            ''
          );
          if (shareLandlord) {
            newState['shareLandlord'] = 'true';
          } else {
            newState['shareLandlord'] = 'false';
          }
          const allBp = safeDeepGet(
            this.props,
            ['readResult', 'body', 'allBp'],
            'false'
          );
          newState['allBp'] = allBp;
          if (!isEmpty(newState)) {
            this.setState(newState);
          }
        }
      } else if (this.props.updateResult !== prevProps.updateResult) {
        // Success update info
        const code = safeDeepGet(
          this.props,
          ['updateResult', 'code'],
          Result.UNKNOWN_ERROR
        );

        if (code === Result.SUCCESS) {
          this.showToast();
          this.props.history.replace('/role');
        } else if (code === Result.UNKNOWN_ERROR) {
          const code = safeDeepGet(
            this.props,
            ['createResult', 'code'],
            Result.UNKNOWN_ERROR
          );
        } else {
          this.showToastFailed();
        }
      }
    }

    if (this.props.listTagsresult !== prevProps.listTagsresult) {
      const code = safeDeepGet(
        this.props,
        ['listTagsresult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS || code === Result.HTTP.OK) {
        const tagsOptions = safeDeepGet(
          this.props,
          ['listTagsresult', 'body'],
          []
        );
        this.setState({
          tagsOptions,
        });
      }
    }
  }
  render() {
    const {
      isEdit,
      body,
      column,
      bpList,
      columnBp,
      id,
      name,
      permision,
      allBp,
      tagsOptions,
      shareLandlord,
      selectedTag,
      useTag,
    } = this.state;
    const { SearchBar } = Search;
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Management', 'guest'], 'off');
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>{buildTitle('Role')}</title>
            </MetaTags>
            {safeDeepGet(
              navbar,
              ['Management', 'view'],
              safeDeepGet(navbar, ['Account', 'view'], 'off')
            ) == 'off' ? (
              <Pages403 />
            ) : (
              <Container fluid={true}>
                <Breadcrumbs
                  title="Role"
                  breadcrumbItem={isEdit ? 'Edit' : 'Add'}
                />
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">Role Information</CardTitle>
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                        <Row>
                          <Col sm="12">
                            <FormGroup className="mb-3">
                              <Label className="mt-1" htmlFor="id">
                                Role ID :
                              </Label>
                              <AvField
                                type="text"
                                id="id"
                                name="id"
                                value={id}
                                disabled={true}
                                placeholder="Masukan Role ID..."
                                className="form-control"
                                validate={{
                                  required: {
                                    value: true,
                                    pattern: { value: '^[A-Za-z0-9_-]+$' },
                                    //   errorMessage: 'Please en',
                                  },
                                }}
                              />
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label className="mt-1" htmlFor="name">
                                Role Name :
                              </Label>
                              <AvField
                                onChange={this.changeName}
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                placeholder="Masukan Nama Role..."
                                className="form-control"
                                validate={{
                                  required: {
                                    value: true,
                                    pattern: { value: '^[A-Za-z0-9_-]+$' },
                                    //   errorMessage: 'Please en',
                                  },
                                }}
                              />
                            </FormGroup>

                            <FormGroup className="mb-3">
                              <Label className="mt-1" htmlFor="id">
                                Navigation :
                              </Label>
                              <BootstrapTable
                                keyField="id"
                                bootstrap4={true}
                                data={body}
                                columns={column}
                                cellEdit={cellEditFactory({
                                  mode: 'click',
                                  blurToSave: true,
                                })}
                                bordered={false}
                                ref={this.navRef}
                              />
                            </FormGroup>

                            <FormGroup className="mb-3">
                              <Row>
                                <Col>
                                  <Label
                                    className="mt-1 d-flex align-items-center"
                                    htmlFor="id"
                                  >
                                    Pakai Tag:
                                    <Tippy content="Tag digunakan untuk memilih Business Point yang ingin digunakan. Daftarkan tag terlebih dahulu di Business Point, lalu pilih tag yang akan dipakai.">
                                      <span className="ms-1">
                                        <Link className="text-info" to="#">
                                          <i
                                            className="mdi mdi-alert-circle-outline font-size-15"
                                            id="menutooltip"
                                            onClick={() => {}}
                                          ></i>
                                        </Link>
                                      </span>
                                    </Tippy>
                                  </Label>
                                  <AvRadioGroup
                                    value={useTag}
                                    defaultChecked
                                    inline
                                    name="useTag"
                                    required
                                    errorMessage="Pilih salah satu!"
                                    onClick={this.handleCheckedTag}
                                  >
                                    <AvRadio label="Ya" value={'true'} />{' '}
                                    <AvRadio label="Tidak" value={'false'} />
                                  </AvRadioGroup>
                                </Col>

                                <Col>
                                  {useTag == 'true' ? (
                                    <>
                                      {' '}
                                      <Label
                                        className="mt-1 d-flex align-items-center"
                                        htmlFor="id"
                                      >
                                        Bagi hasil dengan Landlord berdasarkan
                                        Share Revenue
                                        <Tippy content="Bagi hasil berarti sebagian dari pendapatan akan dibagikan kepada pemilik properti sesuai kesepakatan., Tambahkan berapa persen pada Business Point">
                                          <Link
                                            className="text-info ms-1"
                                            to="#"
                                            onClick={e => e.preventDefault()}
                                          >
                                            <i className="mdi mdi-alert-circle-outline font-size-15"></i>
                                          </Link>
                                        </Tippy>
                                      </Label>
                                      <AvRadioGroup
                                        value={shareLandlord}
                                        defaultChecked
                                        inline
                                        name="shareLandlord"
                                        required
                                        className="mt-2"
                                        errorMessage="Pilih salah satu!"
                                        onClick={
                                          this.handleCheckedShareLandlord
                                        }
                                      >
                                        <AvRadio label="Ya" value={'true'} />{' '}
                                        <AvRadio
                                          label="Tidak"
                                          value={'false'}
                                        />
                                      </AvRadioGroup>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>

                            <FormGroup className="mb-3"></FormGroup>

                            {useTag == 'true' ? (
                              <FormGroup className="mb-3">
                                <Label className="mt-1" htmlFor="tags">
                                  Tag Role:
                                </Label>
                                <Select
                                  value={tagsOptions.filter(function (option) {
                                    return option.value === selectedTag;
                                  })}
                                  onChange={this.handleTagChange}
                                  options={tagsOptions}
                                  classNamePrefix="select2-selection"
                                />
                              </FormGroup>
                            ) : (
                              <>
                                <FormGroup className="mb-3">
                                  <Label className="mt-1" htmlFor="id">
                                    All Business Point :
                                  </Label>
                                  <AvRadioGroup
                                    value={allBp}
                                    inline
                                    name="allBp"
                                    required
                                    errorMessage="Pilih salahsatu!"
                                    onClick={this.handleChecked}
                                  >
                                    <AvRadio label="Semua" value="true" />{' '}
                                    <AvRadio label="Sebagian" value="false" />
                                  </AvRadioGroup>
                                </FormGroup>

                                <FormGroup className="mb-3">
                                  <Label className="mt-1" htmlFor="id">
                                    Business Point :
                                  </Label>
                                  <FormGroup className="mb-3"></FormGroup>
                                  <BootstrapTable
                                    keyField="name"
                                    bootstrap4={true}
                                    data={bpList}
                                    columns={columnBp}
                                    cellEdit={cellEditFactory({
                                      mode: 'click',
                                      blurToSave: true,
                                    })}
                                    bordered={false}
                                    ref={this.bpRef}
                                  />
                                </FormGroup>
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Link
                        className="btn btn-secondary"
                        role="button"
                        to="/role"
                      >
                        Batal
                      </Link>{' '}
                      {guest == 'off' ? (
                        <Button type="submit" color="primary">
                          Simpan
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  test: state,
  initCreateResult: safeDeepGet(state, ['role', 'initCreate'], {}),
  createResult: safeDeepGet(state, ['role', 'create'], {}),
  loading: safeDeepGet(state, ['role', 'loading'], true),
  readResult: safeDeepGet(state, ['role', 'read'], {}),
  updateResult: safeDeepGet(state, ['role', 'update'], {}),
  listTagsresult: safeDeepGet(state, ['tag', 'list_tags'], {}),
});

const mapDispatchToProps = dispatch => ({
  InitCreateRole: () => dispatch(initCreate()),
  CreateRole: payload => dispatch(create(payload)),
  ReadRole: code => dispatch(read(code)),
  UpdateRole: payload => dispatch(update(payload)),
  GetTags: () => dispatch(list_tags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRole);
